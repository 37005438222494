/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React, { useEffect } from "react"
import "./src/scss/index.scss"

import GlobalStyles from "./src/styles/global-styles"
import { ContextProvider } from "./src/context/AppContext"
import { LocationContext } from "./src/context/LocationContext"
import { parseParams } from "./src/utils/utils"

export const wrapRootElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>
}

export const wrapPageElement = ({ element }) => {
  const path = element.props.path;
  const params = parseParams(element.props.location.search)

  useEffect(() => {
    // Construct a new URL with the parameters
    const newUrl = params.size ? `${path}?${params.toString()}` : path;

    // Update the current URL without reloading the page using the native browser API
    window.history.replaceState({}, '', newUrl);
  }, [params, path]);

  return (
    <>
      <GlobalStyles />
      <LocationContext.Provider value={{ params, path }}>
        {element}
      </LocationContext.Provider>
    </>
  )
}
