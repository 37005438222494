import { css } from "styled-components"
import { createGlobalStyle } from "styled-components";
import respond from "../abstracts/mediaqueries";

export default createGlobalStyle`
 .button-container {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: var(--gutter);
    place-items: center;
    max-width: max-content;
    margin: 0 auto;

    ${respond(450, css`
      grid-template-columns: max-content;
      /* Center the button */
      justify-content: center;
    `)}

    .btn {
      color: var(--white);
      padding: 1.5rem 3rem;
      border-radius: 50px;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-0.2rem);
        box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);
      }
      &--primary {
        background-color: var(--appointment-button-color);

        &:hover {
          background-color: var(--appointment-button-hover-color);
        }
      }

      &--secondary {
        background-color: var(--dental-offer-button-color);
        color: var(--color-primary);

        &:hover {
          background-color: var(--dental-offer-button-hover-color);
        }
      }
    }
  }
`;