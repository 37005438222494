import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
/* ----------------------------------------------
 * Generated by Animista on 2021-9-14 19:23:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
  @keyframes scale-up-center {
    0% {
        transform: scale(0) translate(-50%, -50%);
    }
    50% {
        transform: scale(0) translate(-50%, -50%);
    }
    100% {
        transform: scale(1) translate(-50%, -50%);
    }
  }
  
  .fadeInUpBig {
        -webkit-animation-name: fadeInUpBig;
        animation-name: fadeInUpBig;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    @keyframes fadeInUpBig {
        0% {
        opacity: 0;
        transform: translate(-50%, 2000px);
        }
        100% {
        opacity: 1;
        
        transform: translate(-50%, 0);
        }
  } `;
